import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "filter",
  initialState: {
    settingsData: "",
  },
  reducers: {
    setSettingData: (state, action) => {
      state.settingsData = action.payload;
    },
    clearSettingData: (state, action) => {
      state.settingsData = "";
    },
  },
});

export const { setSettingData, clearSettingData } = settingsSlice.actions;

export default settingsSlice.reducer;
